import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { category } from '../../config/category'

const Header = (prop: { location: Array<string> }): JSX.Element => {
  const location = prop.location

  const disCategory = category.filter((cate: { name: string; id: number; value: string }) => cate.name !== 'None')

  return (
    <>
      <header className="header__header">
        {location && location.length > 2 && (
          <p className="header__p">
            <a href="/">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Fheader_logo.png?alt=media&token=3f14173b-c6c2-42da-9f75-ed6e01d70e7d"
                alt="KURORO BLOGのロゴ"
                referrerPolicy="no-referrer"
                width={120}
                height={120}
                className="header__img"
              />
            </a>
          </p>
        )}
        {location && location.length <= 2 && (
          <h1 className="header__h1">
            <a href="/">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/kuro-site.appspot.com/o/images%2Fheader_logo.png?alt=media&token=3f14173b-c6c2-42da-9f75-ed6e01d70e7d"
                alt="KURORO BLOGのロゴ"
                referrerPolicy="no-referrer"
                width={120}
                height={120}
                className="header__img"
              />
            </a>
          </h1>
        )}
        <div className="hamburgerMenu">
          <input type="checkbox" id="menuBtnCheck" />
          <label htmlFor="menuBtnCheck" className="menuBtn">
            <span></span>
          </label>
          <div className="menuContent">
            <ul className="menuContentOtherList">
              <li>
                <a href="/">
                  <span>ホーム</span>
                </a>
              </li>
              <li>
                <a href="/smap/">
                  <span>サイトマップ</span>
                </a>
              </li>
              <li>
                <a href="/contacts/">
                  <span>お問い合わせ一覧</span>
                </a>
              </li>
              <li>
                <a href="/profile/">
                  <span>プロフィール詳細</span>
                </a>
              </li>
              <li>
                <a href="/mutual-link/">
                  <span>おすすめリンク集</span>
                </a>
              </li>
            </ul>
            <div className="menuContentTitle">カテゴリー</div>
            <ul className="menuContentCategoryList">
              {disCategory.map(
                (
                  category: {
                    name: string
                    value: string
                    children: Array<{
                      name: string
                      value: string
                    }>
                  },
                  idx: number
                ) => {
                  return (
                    <>
                      <li key={idx}>
                        <a href={'/' + category.name + '/'}>
                          <span>{category.value}</span>
                        </a>
                      </li>
                      {category.children.length > 0 && (
                        <>
                          {category.children.map((child: { name: string; value: string }) => {
                            return (
                              <li key={idx}>
                                <a href={'/' + child.name + '/'}>
                                  <span>{child.value}</span>
                                </a>
                              </li>
                            )
                          })}
                        </>
                      )}
                    </>
                  )
                }
              )}
            </ul>
            <div className="menuContentTitle">キーワードで検索</div>
            <div className="menuContentSearch">
              <form action="/search/" className="side__search">
                <input className="side__search--input" type="search" name="q" placeholder="検索" autoComplete="on" />
                <input className="side__search--inputBtn" type="submit" value="" />
              </form>
            </div>
          </div>
        </div>
      </header>

      <nav className="navigation" itemProp="hasPart" itemScope itemType="http://schema.org/SiteNavigationElement">
        <ul className="navigationList">
          {disCategory.map(
            (
              category: {
                name: string
                value: string
                children: Array<{
                  name: string
                  value: string
                }>
              },
              idx: number
            ) => {
              return (
                <>
                  <li
                    className="navigationList--li"
                    key={idx}
                    itemProp="hasPart"
                    itemScope
                    itemType="http://schema.org/WebPage"
                  >
                    <a itemProp="url" className="navigationList--a" href={'/' + category.name + '/'}>
                      <span itemProp="name">{category.value}</span>
                    </a>
                  </li>
                  {category.children.length > 0 && (
                    <>
                      {category.children.map((child: { name: string; value: string }) => {
                        return (
                          <li
                            className="navigationList--li"
                            key={idx}
                            itemProp="hasPart"
                            itemScope
                            itemType="http://schema.org/WebPage"
                          >
                            <a className="navigationList--a" href={'/' + child.name + '/'} itemProp="url">
                              <span itemProp="name">{child.value}</span>
                            </a>
                          </li>
                        )
                      })}
                    </>
                  )}
                </>
              )
            }
          )}
        </ul>
      </nav>

      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <div className="darkButton">
            <input
              type="checkbox"
              id="toggle"
              onChange={(e) => toggleTheme(e.target.checked ? 'dark' : 'light')}
              checked={theme === 'dark'}
            />
            <label htmlFor="toggle"></label>
          </div>
        )}
      </ThemeToggler>
    </>
  )
}
export default Header
