import React from 'react'

const Breadcrumb = (props: {
  breadcrumb: Array<{
    link: string
    text: string
  }>
}): JSX.Element => {
  /**
   * @link https://schema.org/BreadcrumbList
   */
  return (
    <nav className="breadcrumb__breadcrumb">
      <ol className="breadcrumb__inner" itemScope itemType="http://schema.org/BreadcrumbList">
        {props.breadcrumb &&
          props.breadcrumb.map(
            (
              bread: {
                link: string
                text: string
              },
              idx: number
            ) => {
              if (props.breadcrumb.length - 1 === idx) {
                return (
                  <li
                    className="breadcrumb__li"
                    itemScope
                    itemProp="itemListElement"
                    itemType="http://schema.org/ListItem"
                    key={idx}
                  >
                    <span className="breadcrumb__span" itemProp="name">
                      {bread.text}
                    </span>
                    <meta itemProp="position" content={`${idx + 1}`} />
                  </li>
                )
              } else {
                return (
                  <li
                    className="breadcrumb__li"
                    itemScope
                    itemProp="itemListElement"
                    itemType="http://schema.org/ListItem"
                    key={idx}
                  >
                    <a href={bread.link} className="breadcrumb__a" itemProp="item">
                      <span itemProp="name">{bread.text}</span>
                    </a>
                    <meta itemProp="position" content={`${idx + 1}`} />
                  </li>
                )
              }
            }
          )}
      </ol>
    </nav>
  )
}
export default Breadcrumb
